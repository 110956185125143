<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Add Cinema Charges</strong>
            <small>Form</small>
          </div>

          <b-form v-on:submit.prevent.self="submit">
            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="organization">
                    Organization
                    <span class="text-danger">*</span>
                  </label>
                  <!-- <b-form-input type="text" v-model="cinema.Organization" id="organization" placeholder="Organization"></b-form-input> -->
                  <b-form-select
                    required
                    @change.native="getCinemaList($event.target.value)"
                    v-model="cinemaCharges.OrganizationID"
                    id="organization"
                    :plain="true"
                    value-field="OrganizationID"
                    text-field="OrganizationName"
                    :options="organizations"
                  >
                    <template slot="first">
                      <option :value="0" disabled>-- Select Organization --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="cinemas">
                    Cinema
                    <span class="text-danger">*</span>
                  </label>
                  <!-- <b-form-input type="text" v-model="cinema.Country" id="country" placeholder="Country"></b-form-input> -->
                  <b-form-select
                    required
                    v-model="cinemaCharges.CinemaID"
                    id="cinemas"
                    :plain="true"
                    value-field="CinemaID"
                    text-field="CinemaName"
                    :options="cinemas"
                  >
                    <template slot="first">
                      <option :value="0" disabled>-- Select Cinema --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="screens">
                    Charge Mode
                    <span class="text-danger">*</span>
                  </label>
                  <!-- <b-form-input type="text" v-model="cinema.Country" id="country" placeholder="Country"></b-form-input> -->
                  <b-form-select
                    required
                    v-model="cinemaCharges.ChargeMode"
                    id="screens"
                    :plain="true"
                    :options="['Select a Mode','Absolute', 'Percentage']"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="date">
                    Charge Value
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input  type="number" step="any" required v-model="cinemaCharges.ChargeValue" id="id"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="charge_type">
                    Charge Type(Conveyance)
                    <span class="text-danger">*</span>
                  </label>
                  <!-- <b-form-input type="text" v-model="cinema.Country" id="country" placeholder="Country"></b-form-input> -->
                  <b-form-input type="text" required v-model="cinemaCharges.ChargeType" id="movies"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="schedule">
                    Tax Percentage
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                     type="number" step="any"
                    required
                    v-model="cinemaCharges.TaxPercentage"
                    id="id"
                    placeholder="Tax Percentage"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="status">Status</label>
                  <br />
                  <c-switch
                    class="mx-1"
                    color="primary"
                    v-model="cinemaCharges.CinemaChargesIsActive"
                    defaultChecked
                    variant="3d"
                    label
                    v-bind="labelIcon"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="6">
                <b-button type="submit" size="sm" variant="primary">
                  <i class="fa fa-dot-circle-o"></i>
                  {{ commontext.submitButton }}
                </b-button>
              </b-col>
              <b-col sm="6">
                <b-button @click="resetForm" size="sm" variant="success">
                  <i class="fa fa-dot-circle-o"></i> Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import OrganizationService from "@/services/OrganizationService";
import CinemaService from "@/services/CinemaService";
import ScreenService from "@/services/ScreenService";
import MovieService from "@/services/MovieService";
import { Switch as cSwitch } from "@coreui/vue";
export default {
  name: "AddCinemaCharges",
  components: {
    cSwitch
  },
  data() {
    return {
      commontext: {
        submitButton: "Submit"
      },
      cinemaCharges: {
        CinemaChargesID: null,
        OrganizationID: 0,
        CinemaID: 0,
        Flag: 1,
        ChargeMode: "Select a Mode",
        ChargeValue: 0,
        ChargeType: "",
        TaxPercentage: "",
        CinemaChargesIsActive: true
      },
      organizations: [],
      cinemas: [],
      screens: [],
      movies: [],
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  mounted() {
    this.getOrganization();
    const { CinemaChargesID } = this.$route.query;
    if (CinemaChargesID) {
      this.getCinemaChargesByID(CinemaChargesID);
    }
  },
  methods: {
    resetForm: function() {
      this.cinemaCharges = {
        CinemaChargesID: null,
        OrganizationID: 0,
        CinemaID: 0,
        Flag: 1,
        ChargeMode: "Select a Mode",
        ChargeValue: 0,
        ChargeType: "",
        TaxPercentage: "",
        CinemaChargesIsActive: true
      };
      this.commontext.submitButton = "Submit";
      this.cinemas = [];
      window.location.replace(window.location.href.split("?")[0]);
    },
    getCinemaChargesByID: function(CinemaChargesID) {
      // await this.selectCountry(res.data[0].CountryID)
      // await this.selectState(res.data[0].StateID)
      let payload = {
        CinemaChargesID
      };
      CinemaService.getCinemaChargesByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CinemaChargesList.length > 0) {
            this.commontext.submitButton = "Update";

            this.cinemaCharges = data.CinemaChargesList[0];
            this.cinemaCharges.Flag = 2;
            // console.log(
            //   "this.cinemaCharges.OrganizationID",
            //   this.cinemaCharges.OrganizationID
            // );
            this.getCinemaList(this.cinemaCharges.OrganizationID);
            this.cinemaCharges.CinemaID = data.CinemaChargesList[0].CinemaID;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getOrganization: function() {
      this.organizations = [];
      OrganizationService.getOrganizationDDL()
        .then(response => {
          const { data } = response;
          if (data.Status && data.OrganizationList.length > 0) {
            this.organizations = data.OrganizationList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCinemaList: function(OrgID) {
      this.cinemas = [];
      let payload = {
        OrgID
      };
      CinemaService.getActiveCinemas(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CinemaList.length > 0) {
            this.cinemas = data.CinemaList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    submit: function() {
      if (
        this.cinemaCharges.ChargeMode != "Select a Mode" &&
        this.cinemaCharges.CinemaID != 0
      ) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      } else {
        this.showMessageSnackbar(
          this.cinemaCharges.CinemaID == 0
            ? "Select a Cinema"
            : "Select a Charge Mode"
        );
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");

      CinemaService.addCinemaCharges(this.cinemaCharges)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            this.showMessageSnackbar(data.Message);
            this.resetForm();
            this.$router.push({
              path: "/org-management/cinemas/all-cinemas"
            });
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Catch on AddCinemaCharge-onSubmit Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
.fa-plus-square-o {
  font-size: 20px;
  cursor: pointer;
}
</style>
